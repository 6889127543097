import {
  ListOrdered,
  ListTodo,
  Loader2,
  Settings2,
  ShieldCheckIcon,
  Upload,
  UserPlus,
} from "lucide-react";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../components/ui/command";
import { Link, useNavigation } from "@remix-run/react";
import React from "react";
import { Button } from "./ui/button";
import LinkButton from "./LinkButton";

export const menuList = [
  {
    group: "Upload",
    items: [
      {
        link: "/admin/upload",
        text: "Upload CSV",
        icon: <Upload />,
      },
      {
        link: "/comingsoon",
        text: "Upload preferences",
        icon: <Settings2 />,
      },
    ],
  },
  {
    group: "Invite",
    items: [
      {
        link: "/admin/invite",
        text: "Invite a new user",
        icon: <UserPlus />,
      },
    ],
  },
  {
    group: "Pack",
    items: [
      {
        link: "/pack",
        text: "Packing List",
        icon: <ListTodo />,
      },
      {
        link: "/admin/custom-order",
        text: "Custom Packing Order",
        icon: <ListOrdered />,
      },
    ],
  },
];

export default function Sidebar() {
  const navigation = useNavigation();

  return (
    <div className="relative top-0 flex flex-col gap-4 w-56 min-w-56 p-4 min-h-screen">
      <LinkButton variant="ghost" className="flex justify-start" href="/admin">
        <ShieldCheckIcon />
        <h1 className="text-lg font-medium pl-2">Admin Page</h1>
      </LinkButton>
      <div className="grow">
        <Command style={{ overflow: "visible" }}>
          <CommandList style={{ overflow: "visible" }}>
            {menuList.map((menu, menuIndex) => (
              <React.Fragment key={menuIndex}>
                <CommandGroup key={menu.group} heading={menu.group}>
                  {menu.items.map((option) => (
                    <Link to={option.link} key={option.link}>
                      <CommandItem
                        disabled={navigation.location?.pathname === option.link}
                        key={option.text}
                        className="flex gap-2 py-3 hover:cursor-pointer font-medium"
                      >
                        {option.icon}
                        {option.text}
                        {navigation.location?.pathname === option.link && (
                          <Loader2 className="mr-2 h-4 w-4 animate-spin right-0" />
                        )}
                      </CommandItem>
                    </Link>
                  ))}
                </CommandGroup>
                <CommandSeparator
                  key={`separator-${menuIndex}`}
                  className="bg-primary"
                />
              </React.Fragment>
            ))}
          </CommandList>
        </Command>
      </div>
    </div>
  );
}
