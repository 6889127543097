import { MenuIcon, ShieldCheckIcon } from "lucide-react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../components/ui/drawer";
import { menuList } from "./Sidebar";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "./ui/command";
import React from "react";
import { Link } from "@remix-run/react";
import LinkButton from "./LinkButton";

export default function MobileSidebar() {
  if (typeof window === "undefined") return null;
  else
    return (
      <Drawer direction="left">
        <DrawerTrigger className="z-50">
          <MenuIcon width={30} height={30} />
        </DrawerTrigger>
        <DrawerHeader className="hidden">
          {/* for screen readers to avoid warning */}
          <DrawerTitle>Mobile Sidebar</DrawerTitle>
          <DrawerDescription>
            Sidebar to be used by mobile and smaller screens
          </DrawerDescription>
        </DrawerHeader>
        <DrawerContent>
          <Content />
        </DrawerContent>
      </Drawer>
    );
}

const Content = () => {
  return (
    <div className="fixed left-0 flex flex-col gap-4 w-56 min-w-56 p-4 min-h-screen">
      <div className="grow">
        <Command style={{ overflow: "visible" }}>
          <DrawerClose asChild>
            <LinkButton
              variant="ghost"
              className="flex justify-start"
              href="/admin"
            >
              <ShieldCheckIcon />
              <h1 className="text-lg font-medium pl-2">Admin Page</h1>
            </LinkButton>
          </DrawerClose>
          <CommandList style={{ overflow: "visible" }}>
            {menuList.map((menu, menuIndex) => (
              <React.Fragment key={menuIndex}>
                <CommandGroup key={menu.group} heading={menu.group}>
                  {menu.items.map((option, optionIndex) => (
                    <DrawerClose asChild key={optionIndex}>
                      <Link to={option.link} key={option.link}>
                        <CommandItem
                          key={option.text}
                          className="flex gap-2 py-3 hover:cursor-pointer font-medium"
                        >
                          {option.icon}
                          {option.text}
                        </CommandItem>
                      </Link>
                    </DrawerClose>
                  ))}
                </CommandGroup>
                <CommandSeparator
                  key={`separator-${menuIndex}`}
                  className="bg-primary"
                />
              </React.Fragment>
            ))}
          </CommandList>
        </Command>
      </div>
    </div>
  );
};
