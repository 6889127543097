import { captureRemixErrorBoundaryError } from "@sentry/remix";
/**
 * @file root.tsx
 * Entry point for the Remix application (always rendered no matter which route).
 * Defines the root component and initializes application-wide configurations and state.
 */

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import { LoaderFunctionArgs, json } from "@remix-run/node";
import PacksterHeader from "./components/PacksterHeader";
import {
  getPacksterRoleFromJWT,
  getSupabaseWithHeaders,
} from "./utils/supabase.server";
import Sidebar from "./components/Sidebar";
import { useEffect, useState } from "react";
import { GlobalLoading } from "./components/GlobalLoading";
import { Toaster } from "sonner";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html className="h-full" lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full w-full overflow-hidden">
        {children}
        <ScrollRestoration />
        <Scripts />
        <Toaster richColors position="top-center" closeButton />
      </body>
    </html>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  const { supabase, headers } = getSupabaseWithHeaders({ request });
  const {
    data: { user },
  } = await supabase.auth.getUser();
  const role = user
    ? await getPacksterRoleFromJWT({ supabase, headers })
    : null;

  return json({ user: user, role: role }, { headers });
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const { user, role } = useLoaderData<typeof loader>();
  const isAdmin = role === "tenant-admin";

  const location = useLocation();
  const [renderSidebar, setRenderSidebar] = useState(false);

  // to avoid mismatch html between server and client
  useEffect(() => {
    const noSidebarPaths = ["/login", "/signup"];
    setRenderSidebar(!noSidebarPaths.includes(location.pathname) && isAdmin);
  }, [isAdmin, location.pathname]);

  return (
    <div className="flex flex-col h-full">
      <div className="min-w-full h-[72px] ">
        <PacksterHeader user={user} isAdmin={isAdmin} />
      </div>
      <GlobalLoading />

      <div className="flex flex-1 max-h-[calc(100%-72px)]">
        {renderSidebar && (
          <div className="md:min-w-56 md:block hidden  border-r min-h-full max-w-[10%]">
            <Sidebar />
          </div>
        )}
        <main
          className={`grow max-h-full max-w-full ${renderSidebar ? "md:max-w-[89%]" : ""} overflow-hidden`}
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
}
