import { Link } from "@remix-run/react";
import { Button } from "./ui/button";
import { User } from "@supabase/supabase-js";
import { useMediaQuery } from "../utils/useMediaQuery";
import MobileSidebar from "./MobileSidebar";
import { useEffect, useState } from "react";

interface PacksterHeaderProps {
  user: User | null;
  isAdmin: boolean;
}

export default function PacksterHeader({ user, isAdmin }: PacksterHeaderProps) {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [isClient, setIsClient] = useState(false);
  // to avoid mismatch html between server and client
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-fit bg-primary shadow-md z-10 px-6 py-4">
      <div className=" flex items-center justify-between text-primary-foreground">
        {isClient && isAdmin && !isDesktop && <MobileSidebar />}
        <h1 className="text-xl font-bold">Packster</h1>
        <div className="flex items-center space-x-4">
          {user ? (
            <>
              {isDesktop && <span>{user.email}</span>}
              <form action="/logout" method="post">
                <Button type="submit" variant={"destructive"}>
                  Logout
                </Button>
              </form>
            </>
          ) : (
            <Button variant={"secondary"}>
              <Link to="/login">Login</Link>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
